import {
  ADD_EDIT_STAFF,
  ADD_EDIT_STAFF_ERROR,
  ADD_EDIT_STAFF_SUCCESS,
  CHANGE_STAFF_ROLE,
  CLEAR_STAFF_FEATURES,
  DELETE_STAFFS,
  DELETE_STAFFS_ERROR,
  DELETE_STAFFS_SUCCESS,
  FETCH_RESET_STAFF,
  FETCH_TOTAL_STAFFS,
  FETCH_TOTAl_STAFFS_SUCCESS,
  FETCH_STAFFS,
  FETCH_STAFFS_ERROR,
  FETCH_STAFFS_SUCCESS,
  FETCH_STAFF_BY_ID,
  FETCH_STAFF_BY_ID_ERROR,
  FETCH_STAFF_BY_ID_SUCCESS,
  FILTER_BY_APPLICATIONS,
  SET_IS_CUSTOMER,
  SET_STAFF,
  SET_STAFF_PAGE,
  SET_STAFF_SEARCH,
  SET_STAFF_SIZE_PER_PAGE,
  SET_STAFF_SORT_FIELD,
  SET_STAFF_SORT_ORDER,
} from "./actionTypes";

export const setStaffPage = data => ({
  type: SET_STAFF_PAGE,
  payload: data,
});

export const setStaffSizePerPage = data => ({
  type: SET_STAFF_SIZE_PER_PAGE,
  payload: data,
});

export const setStaffSearch = data => ({
  type: SET_STAFF_SEARCH,
  payload: data,
});

export const setStaffSortField = data => ({
  type: SET_STAFF_SORT_FIELD,
  payload: data,
});

export const setStaffSortOrder = data => ({
  type: SET_STAFF_SORT_ORDER,
  payload: data,
});

export const fetchStaff = data => ({
  type: FETCH_STAFFS,
  payload: data,
});

export const fetchStaffSuccess = data => ({
  type: FETCH_STAFFS_SUCCESS,
  payload: data,
});

export const fetchStaffError = () => ({
  type: FETCH_STAFFS_ERROR,
});

export const fetchStaffById = data => ({
  type: FETCH_STAFF_BY_ID,
  payload: data,
});
export const fetchStaffByIdSuccess = data => ({
  type: FETCH_STAFF_BY_ID_SUCCESS,
  payload: data,
});
export const fetchStaffByIdError = () => ({
  type: FETCH_STAFF_BY_ID_ERROR,
});

export const addEditStaff = data => ({
  type: ADD_EDIT_STAFF,
  payload: data,
});

export const setStaff = data => ({
  type: SET_STAFF,
  payload: data,
});

export const addEditStaffSuccess = data => ({
  type: ADD_EDIT_STAFF_SUCCESS,
  payload: data,
});

export const addEditStaffError = () => ({
  type: ADD_EDIT_STAFF_ERROR,
});

export const deleteStaffs = data => ({
  type: DELETE_STAFFS,
  payload: data,
});

export const deleteStaffsSuccess = data => ({
  type: DELETE_STAFFS_SUCCESS,
  payload: data,
});
export const deleteStaffsError = data => ({
  type: DELETE_STAFFS_ERROR,
  payload: data,
});

export const fetchResetStaff = data => ({
  type: FETCH_RESET_STAFF,
  payload: data,
});

export const fetchTotalStaffs = () => ({
  type: FETCH_TOTAL_STAFFS,
});

export const fetchTotalStaffsSuccess = data => ({
  type: FETCH_TOTAl_STAFFS_SUCCESS,
  payload: data,
});

export const changeStaffRole = data => ({
  type: CHANGE_STAFF_ROLE,
  payload: data,
});

export const setISCustomer = data => ({
  type: SET_IS_CUSTOMER,
  payload: data,
});

export const setFilterByApplications = site_id => ({
  type: FILTER_BY_APPLICATIONS,
  payload: site_id,
});

export const clearStaffFeatures = data => ({
  type: CLEAR_STAFF_FEATURES,
  payload: data,
});
