import axios from "axios";
import store from "store";
import { logoutUser } from "store/actions";
import { clear, get, set } from "./cookie_helper";

const API_URL_ACCOUNT = process.env.REACT_APP_API_ACCOUNT;
// const API_URL_ACCOUNT = "http://192.168.1.181:8087/api/v1";
const API_URL_ADMIN = process.env.REACT_APP_API_ADMIN;
// const API_URL_ADMIN = "http://192.168.1.145:8085/api/v1";
const API_URL_ANALYTICS = process.env.REACT_APP_API_ANALYTICS;
const API_URL_SLIDR = process.env.REACT_APP_API_SLIDR;
const API_URL_BOARD = process.env.REACT_APP_API_BOARD;
// const API_URL_BOARD = "http://192.168.1.181:8087/api/v1";
const API_URL_BUILDER = process.env.REACT_APP_API_BUILDER_URL;
const CLOUD_STORAGE_URL = process.env.REACT_APP_NEXT_CLOUD_SHAREABLE_URL;
const API_URL_CLOUD = process.env.REACT_APP_API_CLOUD;
const API_DATABRIDGE_CIRCLE = process.env.REACT_APP_DATABRIDGE_URL
  ? process.env.REACT_APP_DATABRIDGE_URL
  : process.env.REACT_APP_ENV == 'dev'? "https://databridge.stuffyoucanuse.dev/api":"https://databridge.stuffyoucanuse.org/api";

const axiosApiAccount = axios.create({
  baseURL: API_URL_ACCOUNT,
});
{
  /*arvina changes 23/11/2022 digital board api addition  */
}
const axiosCircleComment = axios.create({
  baseURL: API_DATABRIDGE_CIRCLE,
});
const axiosApiBoard = axios.create({
  baseURL: API_URL_BOARD,
});

const axiosApiAdmin = axios.create({
  baseURL: API_URL_ADMIN,
});

const axiosApiAnalytics = axios.create({
  baseURL: API_URL_ADMIN,
});

const axiosApiSlidr = axios.create({
  baseURL: API_URL_SLIDR,
});

const axiosApiBuilder = axios.create({
  baseURL: API_URL_BUILDER,
});

const axiosCloudStorage = axios.create({
  baseURL: CLOUD_STORAGE_URL,
});

const axiosApiCloud = axios.create({
  baseURL: API_URL_CLOUD,
});

const cloudrequest = req => {
  return req;
};

axiosCloudStorage.interceptors.request.use(cloudrequest);

const non_auth_routes = ["/login"];

const request = req => {
  if (!non_auth_routes.includes(req.url)) {
    const token = get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
  }
  return req;
};

const response = response => {
  if (response?.data?.data?.token) {
    set("token", response.data.data.token);
  }
  return response;
};

const responseError = error => {
  if (error?.response?.status === 401) {
    clear();
    store.dispatch(logoutUser());
  }
  return Promise.reject(error);
};

axiosApiAccount.interceptors.request.use(request);
axiosApiAccount.interceptors.response.use(response, responseError);
axiosApiAdmin.interceptors.request.use(request);
axiosApiAdmin.interceptors.response.use(response, responseError);
axiosApiAnalytics.interceptors.request.use(request);
axiosApiAnalytics.interceptors.response.use(response, responseError);
axiosApiSlidr.interceptors.request.use(request);
axiosApiSlidr.interceptors.response.use(response, responseError);
axiosApiBuilder.interceptors.request.use(request);
axiosApiBuilder.interceptors.response.use(response, responseError);
axiosApiBoard.interceptors.request.use(request);
axiosApiBoard.interceptors.response.use(response, responseError);
axiosApiCloud.interceptors.request.use(request);
axiosApiCloud.interceptors.response.use(response, responseError);
axiosCircleComment.interceptors.request.use(request);
axiosCircleComment.interceptors.response.use(response, responseError);

export {
  axiosApiAccount,
  axiosApiAdmin,
  axiosApiAnalytics,
  axiosApiBoard,
  axiosApiBuilder,
  axiosApiCloud,
  axiosApiSlidr,
  axiosCloudStorage,
  axiosCircleComment,
};
