import {
  FETCH_AVERAGE,
  FETCH_AVERAGE_DATA_ERROR,
  FETCH_AVERAGE_DATA_SUCCESS,
  FETCH_AVERAGE_PAGE,
  FETCH_AVERAGE_PAGE_DATA_ERROR,
  FETCH_AVERAGE_PAGE_DATA_SUCCESS,
  FETCH_BROWSER_DATA,
  FETCH_BROWSER_DATA_ERROR,
  FETCH_BROWSER_DATA_SUCCESS,
  FETCH_DEVICE_DATA,
  FETCH_DEVICE_DATA_ERROR,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_LOCATION_DATA,
  FETCH_LOCATION_DATA_ERROR,
  FETCH_LOCATION_DATA_SUCCESS,
  FETCH_MODULE_X_AXIS_DATA,
  FETCH_OPERATING_SYSTEM_DATA,
  FETCH_OPERATING_SYSTEM_DATA_ERROR,
  FETCH_OPERATING_SYSTEM_DATA_SUCCESS,
  FETCH_PAGES,
  FETCH_PAGES_ERROR,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGE_VIEWS,
  FETCH_PAGE_VIEWS_ERROR,
  FETCH_PAGE_VIEWS_SUCCESS,
  FETCH_SESSIONS,
  FETCH_SESSIONS_ERROR,
  FETCH_SESSIONS_SUCCESS,
  FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS,
  FETCH_TOTAL_VISIT_DATA,
  FETCH_TOTAL_VISIT_DATA_ERROR,
  FETCH_TOTAL_VISIT_DATA_SUCCESS,
  FETCH_REALTIME_DATA,
  FETCH_REALTIME_DATA_SUCCESS,
  FETCH_REALTIME_DATA_ERROR,
  FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS,
  FETCH_UNIQUE_VISIT_DATA,
  FETCH_UNIQUE_VISIT_DATA_ERROR,
  FETCH_UNIQUE_VISIT_DATA_SUCCESS,
  FETCH_X_AXIS_DATA,
  SET_ACTIVE_TAB_DATA,
} from "./actionTypes";

const initialState = {
  loading: false,
  loadingPages: false,
  locationLoading: false,
  browserDataLoading: false,
  OSDataLoading: false,
  deviceDataLoading: false,

  browserData: {
    total: 0,
    rows: [],
  },
  averageSessionData: {
    data: [],
    total_sessions: 0,
    total_time: 0,
    avg_per_session: "",
  },
  averagePageSessionData: {
    dataPage: [],
    total_sessions: 0,
    total_time: 0,
    avg_per_session: "",
  },
  DeviceData: {
    total: 0,
    count: 0,
    avg: 0.0,
    data: [],
  },
  LocationData: {
    total: 0,
    count: 0,
    avg: 0,
    country: [],
    state: [],
    city: [],
    listCountry: [],
  },
  pageData: [],
  totalVisitData: [],
  realtimeData: [],
  appVisitData: [],
  UniqueVisitData: [],
  loadingTotalVisit: false,
  loadingRealTime: false,
  loadingUniqueVisit: false,
  appUniqueVisitData: [],
  XAxisData: [],
  averageXAxisData: [],
  OperatingSystemData: {
    OStotal: 0,
    OSrows: [],
  },
  pages: [],
  sessionsData: {
    data: [],
    sSession: 0,
    pageSessionsData: {
      data: [],
      pSession: 0,
    },
  },
  activeTabData: {
    id: 0,
    label: "All",
  },
  // sessionsData: [],
  // pageSessionsData: []
};

const RealtimeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BROWSER_DATA:
      return {
        ...state,
        browserDataLoading: true,
      };
    case FETCH_BROWSER_DATA_SUCCESS:
      return {
        ...state,
        browserDataLoading: false,
        browserData: {
          ...state.browserData,
          rows: payload.rows,
          total: payload.total ? payload.total : 0,
        },
      };
    case FETCH_BROWSER_DATA_ERROR:
      return {
        ...state,
        browserDataLoading: false,
      };

    //average per session

    case FETCH_AVERAGE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AVERAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        averageSessionData: {
          ...state.averageSessionData,
          data: payload.data,
          total_sessions: payload.total_sessions ? payload.total_sessions : 0,
          total_time: payload.total_time ? payload.total_time : 0,
          avg_per_session: payload.avg_per_session
            ? payload.avg_per_session
            : "",
        },
      };
    case FETCH_AVERAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    /// average page per session

    case FETCH_AVERAGE_PAGE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AVERAGE_PAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        averagePageSessionData: {
          ...state.averagePageSessionData,
          dataPage: payload.data,
          total_sessions: payload.total_sessions ? payload.total_sessions : 0,
          total_time: payload.total_time ? payload.total_time : 0,
          avg_per_session: payload.avg_per_session
            ? payload.avg_per_session
            : "",
        },
      };
    case FETCH_AVERAGE_PAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_DEVICE_DATA:
      return {
        ...state,
        deviceDataLoading: true,
      };
    case FETCH_DEVICE_DATA_SUCCESS:
      return {
        ...state,
        deviceDataLoading: false,
        DeviceData: {
          ...state.DeviceData,
          total: payload.total ? payload.total : 0,
          count: payload.count ? payload.count : 0,
          avg: payload.avg ? payload.avg : 0.0,
          data: payload.data,
        },
      };
    case FETCH_DEVICE_DATA_ERROR:
      return {
        ...state,
        deviceDataLoading: false,
      };
    //fetch location api
    case FETCH_LOCATION_DATA:
      return {
        ...state,
        locationLoading: true,
      };
    case FETCH_LOCATION_DATA_SUCCESS:
      return {
        ...state,
        locationLoading: false,
        LocationData: {
          ...state.LocationData,
          country: payload.country.data,
          listCountry: payload.query.country,
          state: payload.query.state,
          city: payload.query.city,
          total: payload.query.total ? payload.query.total : 0,
          count: payload.query.count ? payload.query.count : 0,
          avg: payload.query.avg ? payload.query.avg : 0.0,
        },
      };
    case FETCH_LOCATION_DATA_ERROR:
      return {
        ...state,
        locationLoading: false,
      };
    case FETCH_PAGE_VIEWS:
      return {
        ...state,
        loadingPages: true,
      };
    case FETCH_PAGE_VIEWS_SUCCESS:
      return {
        ...state,
        loadingPages: false,
        pageData: payload.data,
      };
    case FETCH_PAGE_VIEWS_ERROR:
      return {
        ...state,
        loadingPages: false,
      };
    // Total Visit
    case FETCH_TOTAL_VISIT_DATA:
      return {
        ...state,
        loadingTotalVisit: true,
      };
    case FETCH_TOTAL_VISIT_DATA_SUCCESS:
      return {
        ...state,
        // totalVisitData: action.payload.data.map((visit) => visit.count),
        totalVisitData: payload.filter(
          item => parseInt(item.app_id) !== 9 && item
        ),
        loadingTotalVisit: false,
      };
    case FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS:
      return {
        ...state,
        appVisitData: payload,
        // totalVisitData: payload,
        loadingTotalVisit: false,
      };

    //allTotalVisitData
    case FETCH_TOTAL_VISIT_DATA_ERROR:
      return {
        ...state,
        loadingTotalVisit: false,
      };
      case FETCH_REALTIME_DATA:
        return {
          ...state,
          loadingRealTime: true,
        }
      case FETCH_REALTIME_DATA_SUCCESS:
        return {
          ...state,
          realtimeData: [...payload],
          loadingRealTime: false,
        }
    case FETCH_REALTIME_DATA_ERROR:
      return {
        ...state,
        loadingRealTime: false,
      }
    // Unique Visit
    case FETCH_UNIQUE_VISIT_DATA:
      return {
        ...state,
        loadingUniqueVisit: true,
      };
    case FETCH_UNIQUE_VISIT_DATA_SUCCESS:
      return {
        ...state,
        UniqueVisitData: payload.filter(
          item => parseInt(item.app_id) !== 9 && item
        ),
        loadingUniqueVisit: false,
      };
    case FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS:
      return {
        ...state,
        appUniqueVisitData: payload,
        loadingUniqueVisit: false,
      };
    ///appUniqueVisitData
    case FETCH_UNIQUE_VISIT_DATA_ERROR:
      return {
        ...state,
        loadingUniqueVisit: false,
      };

    //Chart XAxis Data
    case FETCH_X_AXIS_DATA:
      return {
        ...state,
        XAxisData: payload,
        loading: false,
      };

    case FETCH_MODULE_X_AXIS_DATA:
      return {
        ...state,
        averageXAxisData: payload,
        loading: false,
      };

    case FETCH_OPERATING_SYSTEM_DATA:
      return {
        ...state,
        OSDataLoading: true,
      };
    case FETCH_OPERATING_SYSTEM_DATA_SUCCESS:
      return {
        ...state,
        OSDataLoading: false,
        OperatingSystemData: {
          ...state.OperatingSystemData,
          OSrows: payload.rows,
          OStotal: payload.total ? payload.total : 0,
        },
      };

    case FETCH_OPERATING_SYSTEM_DATA_ERROR:
      return {
        ...state,
        OSDataLoading: false,
      };
    //pages
    case FETCH_PAGES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        pages: payload,
      };
    case FETCH_PAGES_ERROR:
      return {
        ...state,
        loading: false,
      };
    //session
    case FETCH_SESSIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sessionsData: {
          ...state.sessionsData,
          data: payload.session.data,
          sSession: payload.session?.sessions,
          pageSessionsData: {
            ...state.pageSessionsData,
            data: payload.pageSession.data,
            pSession: payload?.pageSession?.sessions,
          },
        },
        // sessionsData: payload.session.data,
        // pageSessionsData: payload.pageSession.data,
      };
    case FETCH_SESSIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_ACTIVE_TAB_DATA:
      return {
        ...state,
        activeTabData: payload,
      };
    default:
      return state;
  }
};

export default RealtimeReducer;
