// ne 06 - 01 - 21

//Transaction LOG
export const FETCH_TRANSACTION_LOG = "FETCH_TRANSACTION_LOG";
export const FETCH_TRANSACTION_LOG_SUCCESS = "FETCH_TRANSACTION_LOG_SUCCESS";
export const FETCH_TRANSACTION_LOG_ERROR = "FETCH_TRANSACTION_LOG_ERROR";

// export const
export const FETCH_INVOICE_DATA = "FETCH_INVOICE_DATA";
export const FETCH_INVOICE_DATA_SUCCESS = "FETCH_INVOICE_DATA_SUCCESS";
export const FETCH_INVOICE_DATA_ERROR = "FETCH_INVOICE_DATA_ERROR";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const CLEAR_INVOICE = "CLEAR_INVOICE";