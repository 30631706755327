import {
  FETCH_AVERAGE,
  FETCH_AVERAGE_PAGE,
  FETCH_BROWSER_DATA,
  FETCH_DEVICE_DATA,
  FETCH_LOCATION_DATA,
  FETCH_OPERATING_SYSTEM_DATA,
  FETCH_PAGES,
  FETCH_PAGE_VIEWS,
  FETCH_SESSIONS,
  FETCH_TOTAL_VISIT_DATA,
  FETCH_REALTIME_DATA,
  FETCH_UNIQUE_VISIT_DATA,
  GET_BROWSER_DATA,
} from "./actionTypes";

import {
  FETCH_AVERAGE_PER_PAGE_SESSION_DATA,
  FETCH_AVERAGE_PER_SESSION_DATA,
  FETCH_BROWSERS_CARD_DATA,
  FETCH_BROWSER_DATA_,
  FETCH_DEVICES_DATA,
  FETCH_LOCATIONS_DATA,
  FETCH_OPERATING_CARD_DATA,
  FETCH_PAGE_DATA,
  FETCH_SESSIONS_DATA,
  FETCH_TOTAL_VISIT_DATE_WISE_DATA,
  FETCH_REALTIME_DASHBOARD_DATA,
  FETCH_UNIQUE_REMOTE_IP_DATA,
  GET_PAGES_DATA,
} from "helpers/url_helper";

import { call, put, takeEvery } from "redux-saga/effects";

import { axiosApiAnalytics } from "helpers/api_helper";
import {
  fetchPageViewError,
  fetchPageViewSuccess,
  fetchSinglePagesError,
  fetchSinglePagesSuccess,
  getAveragePagePerSessionError,
  getAveragePagePerSessionSuccess,
  getAveragePerSessionError,
  getAveragePerSessionSuccess,
  getBrowserCardReportError,
  getBrowserCardReportSuccess,
  getDeviceDataError,
  getDeviceDataSuccess,
  getLocationDataError,
  getLocationDataSuccess,
  getOperatingCardReporError,
  getOperatingCardReporSuccess,
  getSessionsDataError,
  getSessionsDataSuccess,
  getTotalVisitCountDataSuccess,
  getTotalVisitDataError,
  getTotalVisitDataSuccess,
  getRealtimeDataSuccess,
  getRealtimeDataError,
  getUniqueVisitDataCountSuccess,
  getUniqueVisitDataError,
  getUniqueVisitDataSuccess,
} from "./actions";

const getBrowserData = data =>
  axiosApiAnalytics.post(FETCH_BROWSERS_CARD_DATA, data);
const getAverageSessionData = data =>
  axiosApiAnalytics.post(FETCH_AVERAGE_PER_SESSION_DATA, data);
const getAveragePageSessionData = data =>
  axiosApiAnalytics.post(FETCH_AVERAGE_PER_PAGE_SESSION_DATA, data);
const getDevicesData = () => axiosApiAnalytics.post(FETCH_DEVICES_DATA);
const getLocationsData = data =>
  axiosApiAnalytics.post(FETCH_LOCATIONS_DATA, data);
const getSessionData = data =>
  axiosApiAnalytics.post(FETCH_SESSIONS_DATA, data);
const getOperatingSystemData = data =>
  axiosApiAnalytics.post(FETCH_OPERATING_CARD_DATA, data);
const getVisitTotalData = data =>
  axiosApiAnalytics.post(FETCH_TOTAL_VISIT_DATE_WISE_DATA, data);
const getRealtimeData = data =>
  axiosApiAnalytics.post(FETCH_REALTIME_DASHBOARD_DATA, data);
const getUniqueVisitData = data =>
  axiosApiAnalytics.post(FETCH_UNIQUE_REMOTE_IP_DATA, data);
const getPagesData = data => axiosApiAnalytics.post(GET_PAGES_DATA + data);
const fetchPageViewData = data => axiosApiAnalytics.post(FETCH_PAGE_DATA, data);
const getBrowserSingleData = data =>
  axiosApiAnalytics.post(FETCH_BROWSER_DATA_, data);

function* handleBrowserData({ payload: data, cb }) {
  try {
    const res = yield call(getBrowserData, data);
    if (res.status && res?.data?.data) {
      yield put(getBrowserCardReportSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getBrowserCardReportError());
  }
}

function* handleFetchAveragePerSessionData({ payload }) {
  try {
    const res = yield call(getAverageSessionData, payload);
    if (res.status && res?.data) {
      yield put(getAveragePerSessionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAveragePerSessionError());
  }
}

function* handleFetchAveragePerPageSessionData({ payload }) {
  try {
    const res = yield call(getAveragePageSessionData, payload);
    if (res.status && res?.data) {
      yield put(getAveragePagePerSessionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAveragePagePerSessionError());
  }
}

//page view

function* handlePageViewData({ payload: data, cb }) {
  try {
    const res = yield call(fetchPageViewData, data);
    if (res.status && res?.data?.data) {
      yield put(fetchPageViewSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(fetchPageViewError());
  }
}

function* handleTotalVisitData({ payload: data, cb }) {
  try {
    const res = yield call(getVisitTotalData, data);
    if (res.status && res?.data?.data) {
      if (data.type === "") {
        yield put(getTotalVisitDataSuccess(res.data.data));
        cb(res.data.data);
      } else {
        yield put(getTotalVisitCountDataSuccess(res.data.data.data));
        cb(res.data.data.data);
      }
    }
  } catch (err) {
    yield put(getTotalVisitDataError());
  }
}

function* handleRealtimeData({ payload: data, cb }) {
  try {
    const res = yield call(getRealtimeData, data);
    if (res.status && res?.data?.data) {
      yield put(getRealtimeDataSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getRealtimeDataError());
  }
}

function* handleUniqueVisitData({ payload: data, cb }) {
  try {
    const res = yield call(getUniqueVisitData, data);
    if (res.status && res?.data?.data) {
      if (data.type === "") {
        yield put(getUniqueVisitDataSuccess(res.data.data));
        cb(res.data.data);
      } else {
        yield put(getUniqueVisitDataCountSuccess(res.data.data.data));
        cb(res.data.data.data);
      }
    }
  } catch (err) {
    yield put(getUniqueVisitDataError());
  }
}

function* handleOperatingSystemData({ payload: data, cb }) {
  try {
    const res = yield call(getOperatingSystemData, data);
    if (res.status && res?.data?.data) {
      yield put(getOperatingCardReporSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getOperatingCardReporError());
  }
}

function* handleDeviceData({ payload: data, cb }) {
  try {
    const res = yield call(getDevicesData, data);
    if (res.status && res?.data?.data) {
      yield put(getDeviceDataSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getDeviceDataError());
  }
}

function* handleLocationData({ payload: data, cb }) {
  try {
    const res = yield call(getLocationsData, data);
    if (res?.data) {
      yield put(getLocationDataSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getLocationDataError());
  }
}

//pages

function* handlePagesData({ payload, cb }) {
  try {
    const res = yield call(getPagesData, payload);
    if (res.status && res.data.data[0].list) {
      yield put(fetchSinglePagesSuccess(res.data.data[0].list));
      cb(res.data);
    }
  } catch (err) {
    yield put(fetchSinglePagesError());
    cb(err);
  }
}

// sessions

function* handleSessionData({ payload: data, cb }) {
  try {
    const res = yield call(getSessionData, data);
    if (res.status && res?.data?.data) {
      yield put(getSessionsDataSuccess(res.data.data));
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getSessionsDataError());
  }
}

function* handleSingleBrowserData({ payload, cb }) {
  try {
    const res = yield call(getBrowserSingleData, payload);
    cb(res);
  } catch (err) {}
}

function* realtimeSaga() {
  yield takeEvery(FETCH_BROWSER_DATA, handleBrowserData);
  yield takeEvery(FETCH_AVERAGE, handleFetchAveragePerSessionData);
  yield takeEvery(FETCH_AVERAGE_PAGE, handleFetchAveragePerPageSessionData);
  yield takeEvery(FETCH_PAGE_VIEWS, handlePageViewData);
  yield takeEvery(FETCH_TOTAL_VISIT_DATA, handleTotalVisitData);
  yield takeEvery(FETCH_REALTIME_DATA, handleRealtimeData);
  yield takeEvery(FETCH_UNIQUE_VISIT_DATA, handleUniqueVisitData);
  yield takeEvery(FETCH_OPERATING_SYSTEM_DATA, handleOperatingSystemData);
  yield takeEvery(FETCH_DEVICE_DATA, handleDeviceData);
  yield takeEvery(FETCH_LOCATION_DATA, handleLocationData);
  yield takeEvery(FETCH_PAGES, handlePagesData);
  yield takeEvery(FETCH_SESSIONS, handleSessionData);
  yield takeEvery(GET_BROWSER_DATA, handleSingleBrowserData);
}

export default realtimeSaga;
