export const FETCH_STAFFS = "FETCH_STAFFS";
export const FETCH_STAFF_BY_ID = "FETCH_STAFF_BY_ID";
export const FETCH_STAFFS_SUCCESS = "FETCH_STAFFS_SUCCESS";
export const FETCH_STAFF_BY_ID_SUCCESS = "FETCH_STAFF_BY_ID_SUCCESS";
export const FETCH_STAFFS_ERROR = "FETCH_STAFFS_ERROR";
export const FETCH_STAFF_BY_ID_ERROR = "FETCH_STAFF_BY_ID_ERROR";
export const SET_STAFF = "SET_STAFF";

export const ADD_EDIT_STAFF = "ADD_EDIT_STAFF";
export const ADD_EDIT_STAFF_SUCCESS = "ADD_EDIT_STAFF_SUCCESS";
export const ADD_EDIT_STAFF_ERROR = "ADD_EDIT_STAFF_ERROR";

export const DELETE_STAFFS = "DELETE_STAFFS";
export const DELETE_STAFFS_SUCCESS = "DELETE_STAFFS_SUCCESS";
export const DELETE_STAFFS_ERROR = "DELETE_STAFFS_ERROR";

export const SET_STAFF_PAGE = "SET_STAFF_PAGE";
export const SET_STAFF_SIZE_PER_PAGE = "SET_STAFF_SIZE_PER_PAGE";
export const SET_STAFF_SEARCH = "SET_STAFF_SEARCH";
export const SET_STAFF_SORT_FIELD = "SET_STAFF_SORT_FIELD";
export const SET_STAFF_SORT_ORDER = "SET_STAFF_SORT_ORDER";
export const SET_IS_CUSTOMER = "SET_IS_CUSTOMER";

export const FETCH_RESET_STAFF = "FETCH_RESET_STAFF";

export const FETCH_TOTAL_STAFFS = "FETCH_TOTAL_STAFFS";
export const FETCH_TOTAl_STAFFS_SUCCESS = " FETCH_TOTAl_STAFFS_SUCCESS";
export const CHANGE_STAFF_ROLE = "CHANGE_STAFF_ROLE";

export const FILTER_BY_APPLICATIONS = "FILTER_BY_APPLICATIONS";
export const CLEAR_STAFF_FEATURES = "CLEAR_STAFF_FEATURES";
