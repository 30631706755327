// Smit : 23-11 / 24-11

import {
  ADD_EDIT_STAFF,
  ADD_EDIT_STAFF_ERROR,
  ADD_EDIT_STAFF_SUCCESS,
  CHANGE_STAFF_ROLE,
  CLEAR_STAFF_FEATURES,
  DELETE_STAFFS,
  DELETE_STAFFS_ERROR,
  DELETE_STAFFS_SUCCESS,
  FETCH_TOTAl_STAFFS_SUCCESS,
  FETCH_STAFFS,
  FETCH_STAFFS_ERROR,
  FETCH_STAFFS_SUCCESS,
  FETCH_STAFF_BY_ID,
  FETCH_STAFF_BY_ID_ERROR,
  FETCH_STAFF_BY_ID_SUCCESS,
  FILTER_BY_APPLICATIONS,
  SET_IS_CUSTOMER,
  SET_STAFF,
  SET_STAFF_PAGE,
  SET_STAFF_SEARCH,
  SET_STAFF_SIZE_PER_PAGE,
  SET_STAFF_SORT_FIELD,
  SET_STAFF_SORT_ORDER,
} from "./actionTypes";

const customerInitialValues = {
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "id",
  sortOrder: "DESC",
  staffRole: 0,
  filterByApplications: 0,
};

const staffInitialValues = {
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "id",
  sortOrder: "DESC",
  staffRole: 0,
};

const initialState = {
  loading: false,
  staffs: [],
  totalSize: 0,
  customer: { ...customerInitialValues },
  staff: {
    ...staffInitialValues,
  },
  totalStaffs: [],
  isCustomer: null,
};

const change = (state, value) =>
  state.isCustomer
    ? {
        customer: {
          ...state.customer,
          ...value,
        },
      }
    : {
        staff: {
          ...state.staff,
          ...value,
        },
      };

const staffsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_STAFF_PAGE:
      return {
        ...state,
        ...change(state, { page: payload }),
      };
    case SET_STAFF_SIZE_PER_PAGE:
      return {
        ...state,
        ...change(state, { sizePerPage: payload }),
      };
    case SET_STAFF_SEARCH:
      return {
        ...state,
        ...change(state, { search: payload, page: 1 }),
      };
    case SET_STAFF_SORT_FIELD:
      return {
        ...state,
        ...change(state, { sortField: payload }),
      };
    case SET_STAFF_SORT_ORDER:
      return {
        ...state,
        ...change(state, { sortOrder: payload }),
      };
    case CHANGE_STAFF_ROLE:
      return {
        ...state,
        ...change(state, { staffRole: payload, page: 1 }),
      };
    case FETCH_STAFFS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        staffs: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_STAFFS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_STAFF_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case FETCH_STAFF_BY_ID_SUCCESS:
      return {
        ...state,
        staff: payload,
        loading: false,
      };
    case FETCH_STAFF_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_EDIT_STAFF:
      return {
        ...state,
        loading: true,
      };
    case ADD_EDIT_STAFF_SUCCESS:
      return {
        ...state,
      };
    case ADD_EDIT_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_STAFFS:
      return {
        ...state,
        loading: true,
      };
    case DELETE_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_STAFFS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TOTAl_STAFFS_SUCCESS:
      return {
        ...state,
        totalStaffs: action.payload,
      };

    case SET_IS_CUSTOMER:
      return {
        ...state,
        isCustomer: payload,
      };
    case SET_STAFF:
      return {
        ...state,
        staff: payload,
      };
    case FILTER_BY_APPLICATIONS:
      return {
        ...state,
        ...change(state, { filterByApplications: payload, page: 1 }),
      };
    case CLEAR_STAFF_FEATURES:
      return {
        ...state,
        ...(!payload.isStaff
          ? { customer: { ...customerInitialValues } }
          : { staff: { ...staffInitialValues } }),
      };
    default:
      return state;
  }
};

export default staffsReducer;
