// Va 05 - 01 - 21

import {
  CLEAR_INVOICE,
  CLEAR_TRANSACTION,
  FETCH_INVOICE_DATA,
  FETCH_INVOICE_DATA_ERROR,
  FETCH_INVOICE_DATA_SUCCESS,
  FETCH_TRANSACTION_LOG,
  FETCH_TRANSACTION_LOG_ERROR,
  FETCH_TRANSACTION_LOG_SUCCESS,
} from "./actionTypes";

const initialState = {
  transaction: [],
  loading: false,
  totalSize: 0,
  sizePerPage: 10,
  loadingInvoice: false,
  invoice: [],
};

const transactionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_INVOICE:
      return {
        ...state,
        invoice: [],
      };
    case CLEAR_TRANSACTION:
      return {
        ...state,
        transaction: [],
      };
    case FETCH_TRANSACTION_LOG:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TRANSACTION_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        transaction: [...state.transaction, ...payload.data.data.rows],
        totalSize: payload.data.data.count,
      };
    case FETCH_TRANSACTION_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_INVOICE_DATA:
      return {
        ...state,
        loadingInvoice: true,
      };
    case FETCH_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        loadingInvoice: false,
        invoice: payload.data.data,
      };
    case FETCH_INVOICE_DATA_ERROR:
      return {
        ...state,
        loadingInvoice: false,
      };
    default:
      return state;
  }
};

export default transactionReducer;
