// Smit : 23-11 / 24-11

import { axiosApiAccount, axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_STAFF,
  EMAIL_RESET_STAFF,
  FETCH_TOTAL_STAFFS_URL,
  FETCH_STAFF_PROFILE_BY_ID,
  LIST_ALL_STAFFS,
  UPDATE_STAFF_PROFILE,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_EDIT_STAFF,
  DELETE_STAFFS,
  FETCH_RESET_STAFF,
  FETCH_TOTAL_STAFFS,
  FETCH_STAFFS,
  FETCH_STAFF_BY_ID,
} from "./actionTypes";

import {
  addEditStaffError,
  deleteStaffsError,
  fetchTotalStaffsSuccess,
  fetchStaff,
  fetchStaffByIdError,
  fetchStaffByIdSuccess,
  fetchStaffError,
  fetchStaffSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import { setStaff } from "./actions";

const fetchStaffs = data => axiosApiAdmin.post(LIST_ALL_STAFFS, data);
const fetchStaffProfileById = id =>
  axiosApiAccount.get(`${FETCH_STAFF_PROFILE_BY_ID}/${id}`);
const deleteStaffs = data => axiosApiAdmin.post(DELETE_STAFF, data);
const addEditStaffs = data => axiosApiAccount.post(UPDATE_STAFF_PROFILE, data);
const fetchResetStaff = data => axiosApiAccount.post(EMAIL_RESET_STAFF, data);
const fetchTotalStaffsCall = () => axiosApiAdmin.get(FETCH_TOTAL_STAFFS_URL);

const getPayloadData = key => ({
  search: key.search.trim(),
  page_record: key.sizePerPage,
  page_no: key.page,
  sortField: key.sortField,
  sortOrder: key.sortOrder,
  staff_role: key.staffRole,
  via_portal: key.filterByApplications,
});

function* handleFetchStaffs() {
  const { isCustomer, customer, staff } = yield select(state => state.Staff);

  const data = {
    ...getPayloadData(isCustomer ? customer : staff),
    is_customer: isCustomer ? 1 : 0,
  };
  try {
    const res = yield call(fetchStaffs, data);
    if (res.data?.data?.data) {
      yield put(fetchStaffSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchStaffError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchStaffById({ payload: { id } }) {
  try {
    const res = yield call(fetchStaffProfileById, id);
    if (res.data?.data?.staff) {
      yield put(fetchStaffByIdSuccess(res.data.data.staff));
    }
  } catch (err) {
    yield put(fetchStaffByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteStaff({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteStaffs, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchStaff());
      cb();
    }
  } catch (err) {
    yield put(deleteStaffsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditStaff({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditStaffs, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      if (data.staff_id) yield put(setStaff(null));
      cb();
    }
  } catch (err) {
    yield put(addEditStaffError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleResetStaff({ payload }) {
  try {
    const res = yield call(fetchResetStaff, payload);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchTotalStaffs() {
  try {
    const res = yield call(fetchTotalStaffsCall);
    if (res?.data?.statusCode === 200 && res.data?.data?.totalStaffs) {
      yield put(fetchTotalStaffsSuccess(res.data.data.totalStaffs));
    }
  } catch (err) {}
}

function* staffSaga() {
  yield takeEvery(FETCH_STAFFS, handleFetchStaffs);
  yield takeEvery(FETCH_STAFF_BY_ID, handleFetchStaffById);
  yield takeEvery(DELETE_STAFFS, handleDeleteStaff);
  yield takeEvery(ADD_EDIT_STAFF, handleAddEditStaff);
  yield takeEvery(FETCH_RESET_STAFF, handleResetStaff);
  yield takeEvery(FETCH_TOTAL_STAFFS, handleFetchTotalStaffs);
}

export default staffSaga;
